// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "appointmentType","customerType","resultContent" ]


  appointmentType(event) {
  	this.appointmentTypeTargets.forEach(target => target.classList.remove("bg-success","active"))
  	this.appointmentTypeTargets.forEach(target => target.getElementsByTagName('a')[0].classList.remove("text-white"))

  	event.currentTarget.parentElement.classList.add("bg-success")
  	event.currentTarget.classList.add("text-white","active")
    var targetOffset = $("#result_container").offset().top - 150;
  	$([document.documentElement, document.body]).animate({
        scrollTop: targetOffset
    }, 500);
  	
  }

  customerType(event) {
  	this.customerTypeTargets.forEach(target => target.classList.remove("bg-success","active"))
  	this.customerTypeTargets.forEach(target => target.getElementsByTagName('a')[0].classList.remove("text-white"))

  	event.currentTarget.parentElement.classList.add("bg-success")
  	event.currentTarget.classList.add("text-white","active")
    var targetOffset = $("#customer_type_result_container").offset().top - 150;
  	$([document.documentElement, document.body]).animate({
        scrollTop: targetOffset
    }, 500);
  	
  }
}
