import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let id = event.item.dataset.id

    let rails_url = this.data.get("url")
    rails_url = rails_url.replace(":id", id).replace(":new_position", event.newIndex + 1)

    Rails.ajax({
      url: rails_url,
      type: 'PATCH'
    })
  }
}