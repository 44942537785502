// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// app/javascript/packs/application.js

// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
import "bootstrap";
import "../stylesheets/application";
import "./progress_bar";
import "./exit_demo_popup";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons/faLongArrowAltRight";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons/faLongArrowAltLeft";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons/faPlayCircle";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faGlasses } from "@fortawesome/free-solid-svg-icons/faGlasses";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faQuestion} from "@fortawesome/free-solid-svg-icons/faQuestion";
import { faUndo} from "@fortawesome/free-solid-svg-icons/faUndo";
import { faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { faMugHot} from "@fortawesome/free-solid-svg-icons/faMugHot";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
library.add(faArrowRight,faLongArrowAltRight,faCheckCircle,faClock,faInfoCircle,faTimesCircle,faStar,faEye,faEyeSlash,faArrowLeft,faLongArrowAltLeft,faPlusCircle,faCaretDown,faCaretRight,faPhone,faPlayCircle,faEnvelope,faExclamationTriangle,faCalendarAlt,faQuestion,faUndo,faGlasses,faExternalLinkAlt,faMugHot,faFacebookSquare,faInstagram);
dom.watch();
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


