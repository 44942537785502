import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button","toggleable" ]

  toggle() {
  	this.toggleableTarget.classList.toggle("d-none")
  	if ( this.toggleableTarget.classList.contains("d-none") ) {
  		this.buttonTarget.innerHTML = "+ Show all opening hours"
  	} else {
  		this.buttonTarget.innerHTML = "- Hide opening hours"
  	}
  }
}
